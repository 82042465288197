export enum UrlParameterFor {
  None = 0,
  SessionId = 1,
  ConfirmAccount = 2,
  ResetPassword = 3,
  Redirected = 4,
  NewPasswordRequired = 5,
}

export class ConfirmUserModel {
  username?: string;
  code?: string;
  type?: number;

  constructor(model: ConfirmUserModel) {
    this.username = model.username || '';
    this.code = model.code || '';
    this.type = model.type || 0;
  }
}

export class SessionModel {
  sessionId?: string;

  constructor(model: SessionModel) {
    this.sessionId = model.sessionId || '';
  }
}

export class RedirectedModel {
  redirectUrl?: string;
  action?: string;

  constructor(model: RedirectedModel) {
    this.redirectUrl = model.redirectUrl || '';
    this.action = model.action || '';
  }
}
