import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private authService: AuthService) {}

  public async assignUserTokenToRedirectUrl(url: string): Promise<string> {
    const idToken = await this.authService.getIdToken(),
      userId = await this.authService.getUserId();

    if (idToken && userId?.length) {
      const regex = new RegExp(/\?/g);

      if (regex.test(url)) {
        url += '&';
      } else {
        url += '?';
      }

      url += `sub_id=${userId}&id_token=${idToken.getJwtToken()}`;
    }

    return url;
  }
}
