import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmAccountState } from 'src/app/shared/enums/auth.enum';
import { Gender } from 'src/app/shared/enums/gender.enum';
import { Handedness } from 'src/app/shared/enums/handedness.enum';
import { UserAccountModel } from 'src/app/shared/models/account';
import {
  CreatePlayerRequest,
  UpdatePlayerRequest,
} from 'src/app/shared/models/player.model';
import { SelectItem } from 'src/app/shared/modules/fs-controls-shared.model';
import { FsGridOptions } from 'src/app/shared/modules/fs-controls/fs-grid/fs-grid.model';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AWSResponseType } from 'src/app/shared/services/error-auth';
import { GlobalService } from 'src/app/shared/services/global.service';
import {
  GenderData,
  HandednessData,
  HandicapRange,
  PasswordMaxLength,
} from 'src/app/shared/static-data';
import { AlertService } from '../alert/alert.service';
import { FsToastService } from 'src/app/shared/modules/fs-popups/fs-toast/fs-toast.service';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
  selector: 'create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent extends FsPageAction implements OnInit {
  readonly PasswordMaxLength = PasswordMaxLength;

  handicapLimits = HandicapRange;

  genderSelectors: SelectItem[];
  handednessSelector: SelectItem[];

  confirmAccount = ConfirmAccountState;

  gridOptions1: FsGridOptions;
  gridOptions2: FsGridOptions;
  isValidPassword: boolean;

  accountStep1FormControls = {
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(250),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.maxLength(PasswordMaxLength),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.maxLength(PasswordMaxLength),
    ]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(250),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(250),
    ]),
  };
  accountStep1Form: FormGroup;

  accountStep2FormControls = {
    handedness: new FormControl(Handedness.Right, []),
    gender: new FormControl(Gender.Male, []),
    handicap: new FormControl(HandicapRange.default, [
      Validators.min(HandicapRange.min),
      Validators.max(HandicapRange.max),
      Validators.maxLength(2),
    ]),
  };
  accountStep2Form: FormGroup;

  constructor(
    frameService: FsPageFrameService,
    public authService: AuthService,
    private builder: FormBuilder,
    public loaderService: FsLoaderService,
    public globalService: GlobalService,
    private router: Router,
    private alertService: AlertService,
    private toastService: FsToastService,
    private helperService: HelperService
  ) {
    super(frameService);
    this.createForms();
    this.title = 'Create Account';
    this.gridOptions1 = new FsGridOptions({
      xsm: [[12], [12], [12], [12], [12], [12], [12], [12]],
      sm: [[12], [12], [12], [12], [12], [12], [12], [12]],
      md: [[12], [12], [12], [12], [12], [12], [12], [12]],
      lg: [[12], [12], [12], [12], [12], [12], [12], [12]],
      xl: [[12], [12], [12], [12], [12], [12], [12], [12]],
      xxl: [[12], [12], [12], [12], [12], [12], [12], [12]],
    });
    this.gridOptions2 = new FsGridOptions({
      xsm: [[12], [12], [12], [12], [12], [12]],
      sm: [[12], [12], [12], [12], [12], [12]],
      md: [[12], [12], [12], [12], [12], [12]],
      lg: [[12], [12], [12], [12], [12], [12]],
      xl: [[12], [12], [12], [12], [12], [12]],
      xxl: [[12], [12], [12], [12], [12], [12]],
    });
    this.isValidPassword = false;
    this.handednessSelector = HandednessData;
    this.genderSelectors = GenderData;
  }

  async ngOnInit(): Promise<void> {
    try {
      if (
        this.authService.isAuthenticated &&
        this.globalService.checkAccountIsCompleted()
      ) {
        this.accountStep2Form.patchValue({ ...this.globalService.player });
        // this.accountStep2FormControls.handedness.setValue(
        //   getHandednessAsString(this.globalService.player.handedness)
        // );
        // this.accountStep2FormControls.gender.setValue(
        //   getGenderAsString(this.globalService.player.gender)
        // );
        // this.accountStep2FormControls.handicap.setValue(
        //   this.globalService.player.handicap
        // );
      }
    } catch (error) {
      console.log(error);
    }
  }

  createForms(): void {
    this.accountStep1Form = this.builder.group({
      ...this.accountStep1FormControls,
    });
    this.accountStep2Form = this.builder.group({
      ...this.accountStep2FormControls,
    });
  }

  async onCreateAccount(): Promise<void> {
    this.loaderService.loader = true;

    const response = await this.authService.signUp(
      new UserAccountModel({
        ...this.accountStep1Form.getRawValue(),
      })
    );

    if (response.error) {
      this.toastService.show('ERROR', response.message);
    } else {
      if (response.type === AWSResponseType.Success) {
        const player = await this.authService.createCompetePlayer(
          new CreatePlayerRequest({
            ...this.accountStep1Form.getRawValue(),
          })
        );

        if (player) {
          this.globalService.player = player;

          window.localStorage.setItem('CompletedAccount', '-1');

          if (!response.data.userConfirmed) {
            this.globalService.currentConfirmAccount =
              ConfirmAccountState.WaitToConfirm;

            this.alertService.showConfirmEmail(
              this.accountStep1FormControls.email.value,
              () => this.onResendEmail(),
              () => this.prevPage('')
            );
          } else {
            this.globalService.currentConfirmAccount =
              ConfirmAccountState.Confirmed;
          }
        } else {
          this.toastService.show('ERROR', 'Create account failed');
        }
      } else {
        this.toastService.show('ERROR', response.message);
      }
    }

    this.loaderService.loader = false;
  }

  async onResendEmail(): Promise<void> {
    this.loaderService.loader = true;

    const result = await this.authService.reSendSignUpConfirm(
      this.accountStep1FormControls.email.value.toLowerCase()
    );

    if (result.type !== AWSResponseType.Success) {
      this.toastService.show('ERROR', result.message);
    }

    this.loaderService.loader = false;
  }

  async onAccountToComplete(): Promise<void> {
    this.loaderService.loader = true;

    const updateRequest = new UpdatePlayerRequest({
      ...this.globalService.player,
      ...this.accountStep2Form.getRawValue(),
    });

    const result = await this.authService.updateCompetePlayer(updateRequest);

    if (result) {
      this.globalService.player.handedness = updateRequest.handedness;
      this.globalService.player.gender = updateRequest.gender;
      this.globalService.player.handicap = updateRequest.handicap;

      window.localStorage.setItem('CompletedAccount', '1');
      await this.checkSessionId();
      this.frameService.resetHistory();
    } else {
      this.toastService.show('ERROR', 'Update failed');
    }

    this.loaderService.loader = false;
  }

  async checkSessionId(): Promise<void> {
    const sessionId = window.localStorage.getItem('SessionId');

    if (sessionId?.length) {
      const joinResult = await this.authService.joinSession(sessionId);

      if (
        joinResult?.status?.toLowerCase() === 'already joined' ||
        joinResult?.status?.toLowerCase() === 'success'
      ) {
        this.alertService.showReadyToPlay(async () => await this.signOut());
      } else {
        this.toastService.show('ERROR', joinResult?.status);
      }
    } else if (this.globalService.fromSessionId) {
      this.globalService.fromSessionId = false;
      this.alertService.showReadyToPlay(async () => await this.signOut());
    } else {
      const url = await this.checkRedirected();

      if (url?.length) {
        this.alertService.showAccountCreatedWithContinue(
          () => {
            window.localStorage.removeItem('redirect_url');
            window.location.href = url;
          },
          async () => await this.signOut()
        );
      } else {
        this.alertService.showAccountCreated(async () => await this.signOut());
      }
    }
  }

  async checkRedirected(): Promise<string> {
    const redirectUrl = window.localStorage.getItem('redirect_url');

    if (redirectUrl?.length) {
      return await this.helperService.assignUserTokenToRedirectUrl(redirectUrl);
    }

    return '';
  }

  async signOut(): Promise<void> {
    this.loaderService.loader = true;

    await this.authService.unautorized(['CompletedAccount']);
    this.router.navigateByUrl('/');

    this.loaderService.loader = false;
  }
}
